/*
 * © 2024 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ManageSettingsSection, SettingsFormValues } from "@/store/state";
import { deprecated, ActionType } from "typesafe-actions";
const { createStandardAction } = deprecated;

export const actions = {
  sectionToggled: createStandardAction(
    "[manage settings] toggle section expansion"
  )<ManageSettingsSection[]>(),

  settingsUpdated: createStandardAction(
    "[manage settings] my settings updated"
  )<SettingsFormValues>(),
};
export type Actions = ActionType<typeof actions>;
